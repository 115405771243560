import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { userLogs } from "../../../../_metronic/helpers/utils"
import moment from "moment";

const TimelineModalForm = ({
  user,
  setOpenTimelineModal,
}) => {
  const [admins, setAdmins] = useState([]);
  const [timeline, setTimeline] = useState([...user.timeline.sort((a, b) => b.id - a.id)]);


  const cancel = () => {
    setOpenTimelineModal(false);
  };


  const adminList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/admin/public`).then((res) => {
      setAdmins(res.data);
    });
  }


  useEffect(() => {
    adminList()
  }, [])



  return (
    <>
      <div className="kt_body">
        <div style={{ maxHeight: '300px', overflowY: 'auto', minHeight: 'auto' }}>
          {
            user.timeline && user.timeline.length > 0 ?
              <>
                {timeline.map((item, index) => {
                  return <div
                    key={index}
                    style={{ display: 'flex', gap: '5px', background: '#f9f9f9', marginBottom: '10px', padding: '10px', borderRadius: '10px', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4 style={{ marginBottom: '2px' }}> {userLogs(item.action_id)}</h4>
                      <h6>{moment(item.createdAt).utc().format('YYYY-MM-DD hh:mm A')}</h6>
                    </div>
                    <h6 style={{ marginBottom: '2px' }}>Action Done By: `${admins?.find((_i) => _i.id == item.action_by)?.name || ""} (Admin)`</h6>
                  </div>
                })
                }
              </>
              :
              <h4>No Stripe logs yet for this user</h4>
          }
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            type="button"
            className="btn mt-4 w-100 btn-primary"
            onClick={() => cancel()}
            style={{
              color: "#fff",
              height: "46px",
              fontWeight: "700",
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export { TimelineModalForm };
