import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PatternFormat } from "react-number-format";
import Calendar from "../../../Calendar";
import moment from "moment";
import { useAuth } from "../../../auth";
import { hours } from "../../../Bookings/editBookingModal/BookingEditModalForm";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GoogleMapReact from 'google-map-react'
import { getVehiclesPrices, parseAddressFromHTMLString, ToastStyle, stateList } from "../../../../../_metronic/helpers/utils";

const ListingEditModalForm = ({
  selectedListingId,
  setSelectedListingId,
  openEditListing,
  setOpenEditListing,
  listing,
  getListing,
}) => {
  const noteRef = useRef(null)
  const { currentUser, haveAccess } = useAuth();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [phone, setPhone] = useState();
  const [billingPhone, setBillingPhone] = useState();
  const [note, setNote] = useState();
  const [signs, setSigns] = useState();
  const [trailersAllowed, setTrailersAllowed] = useState(false);
  const [trucksAllowed, setTrucksAllowed] = useState(false);
  const [optionalTerms, setOptionalTerms] = useState(false);
  const [tnc, setTnc] = useState('');
  const [isQr, setIsQr] = useState(false);
  const [apt, setApt] = useState();
  const [amenities, setAmenities] = useState([]);
  const [address, setAddress] = useState('');
  const [seats, setSeats] = useState('');
  const [tempSeats, setTempSeats] = useState('');
  const [enabledBookings, setEnabledBookings] = useState([1, 2, 3, 4]);
  const [truckHourly, setTruckHourly] = useState('')
  const [trailerHourly, setTrailerHourly] = useState('')
  const [truckTrailerHourly, setTruckTrailerHourly] = useState('')
  const [truckDaily, setTruckDaily] = useState('');
  const [trailerDaily, setTrailerDaily] = useState('');
  const [truckTrailerDaily, setTruckTrailerDaily] = useState('');
  const [truckWeekly, setTruckWeekly] = useState('');
  const [trailerWeekly, setTrailerWeekly] = useState('');
  const [truckTrailerWeekly, setTruckTrailerWeekly] = useState('');
  const [truckMonthly, setTruckMonthly] = useState('');
  const [trailerMonthly, setTrailerMonthly] = useState('');
  const [truckTrailerMonthly, setTruckTrailerMonthly] = useState('');
  const [separatePrices, setSeparatePrices] = useState(false);
  const [hourly, setHourly] = useState("");
  const [daily, setDaily] = useState("");
  const [weekly, setWeekly] = useState("");
  const [monthly, setMonthly] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [details, setDetails] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [images, setImages] = useState([]);
  const [avatar, setAvatar] = useState();
  const [avatarId, setAvatarId] = useState();
  const [calendarData, setCalendarData] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState();
  const [showModal, setShowModal] = useState({
    type: 'single',
    status: false,
    date: "",
  });
  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [bookingsData, setBookingsData] = useState([]);
  const [disabledAllSeats, setDisabledAllSeats] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [openTime, setOpenTime] = useState('');
  const [closeTime, setCloseTime] = useState('');
  const [textCode, setTextCode] = useState('');
  const [video, setVideo] = useState('');
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [vehicleTypeActive, setVehicleTypeActive] = useState('truck')
  const [manualLatLng, setManualLatLng] = useState(false)
  const [generatedName, setGeneratedName] = useState(false)
  const [disableAllSeatsNote, setDisableAllSeatsNote] = useState('')


  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const date = moment().format('YYYY-MM-DD')

  const extractZipCode = (address) => {
    const regex = /(\d{5})(?=\s*,?\s*(?:[A-Za-z]+)?\s*$)/;
    const match = address.match(regex);
    return match ? match[1] : -1;
  }

  const EditorModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'link'],
    ],
  };
  const cancel = () => {
    setSelectedListingId();
    setOpenEditListing(false);
  };

  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);

  const options = {
    componentRestrictions: { country: 'us' },
    requestedLanguage: 'en',
    fields: ['adr_address', 'address_components', 'formatted_address', 'geometry', 'icon', 'name'],
    types: ['address']
  }

  let autocompleteService


  function getPlacePredictions(input) {
    return new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions({
        input, fields: ['address_components', 'formatted_address', 'geometry', 'icon', 'name'],
        types: ['address'],
        componentRestrictions: { country: 'us' }
      }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else {
          reject(new Error('Failed to get place predictions'));
        }
      });
    });
  }


  const handleApiLoaded = async (map, maps) => {
    const { Autocomplete, AutocompleteService } = await maps.importLibrary('places')
    autoCompleteRef.current = new Autocomplete(
      inputRef.current,
      options
    )
    autocompleteService = new AutocompleteService()
    // inputRef.current.addEventListener('blur', () => {
    //   autoCompleteRef.current.getPlace()
    //   if (!autoCompleteRef.current.getPlace()?.address_components && !listing.full_address) {
    //     setAddress('')
    //   }
    // })

    autoCompleteRef.current.addListener('place_changed', async function () {
      const place = await autoCompleteRef.current.getPlace()
      const p_street = parseAddressFromHTMLString(place.adr_address).street
      const p_number = place.address_components.find(el => el.types.indexOf('street_number') > -1)
      const p_state = place.address_components.find(el => el.types.indexOf('administrative_area_level_1') > -1)
      const p_city = parseAddressFromHTMLString(place.adr_address).city
      const p_zip = place.address_components.find(el => el.types.indexOf('postal_code') > -1)
      const p_address = place.formatted_address

      setAddress(p_address)
      setCity(p_city ? p_city : '')
      setState(p_state ? p_state.long_name : '')
      setZip(p_zip ? p_zip.long_name : '')
      setDetails(p_street ? p_street : '')
      setStreetNumber(p_number ? p_number.long_name : '')
      setLat(place.geometry.location.lat()?.toFixed(5))
      setLng(place.geometry.location.lng()?.toFixed(5))
    })

    setIsMapLoaded(true)
  }


  const getAmenities = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/listing/amenities`).then((res) => {
      setAmenitiesList(res.data);
    }).catch((err) => {
      toast("Error Fetching Amenities", ToastStyle);
    });
  }

  useEffect(() => {
    if (openEditListing) {
      getAmenities();
      setName(listing.title);
      setDescription(listing.description);
      setPhone(listing.phone);
      setBillingPhone(listing.billing_phone);
      setNote(listing.notes);
      setSigns(listing.signs);
      setApt(listing.apt);
      setAmenities(listing.amenities || []);
      setSeats(listing.is_zero ? '0' : listing.seats);
      setTempSeats(listing.seats);
      setAddress(listing.full_address);
      setCity(listing.city);
      setState(listing.state);
      setZip(listing.zip);
      setDetails(listing.details);
      setStreetNumber(listing.street_number);
      setLat(listing.lat);
      setLng(listing.lng);
      setImages(listing.images ? listing.images : []);
      setAvatar(listing.avatar);
      setDisabledAllSeats(listing.is_zero);
      if (listing.vehicle_type_allowed === 1) {
        // setTrucksAllowed(true)
        setVehicleTypeActive('truck')
      }
      if (listing.vehicle_type_allowed === 2) {
        // setTrailersAllowed(true)
        setVehicleTypeActive('trailer')
      }
      setOpenTime(listing.open_time ? listing.open_time : "");
      setCloseTime(listing.close_time ? listing.close_time : "");
      setVideo(listing.video);
      setIsQr(listing.is_qr_only)
      setTextCode(listing.textcode)
      if (listing.tnc) {
        setOptionalTerms(true);
        setTnc(listing.tnc);
      }

      if (listing.prices) {
        if (!listing.prices.find((el) => el.vehicle_type > 0)){
          if (listing.prices.length > 0) {
            setEnabledBookings(
              listing.prices
                .filter((el) => el.price)
                .map((el) => el.plan_duration)
            );
          } else {
            setEnabledBookings([]);
          }
        }else{
          setEnabledBookings([1,2,3,4]);
        }
        setSeparatePrices(listing.prices.find((el) => el.vehicle_type > 0) ? true : false);
        setHourly(getVehiclesPrices(listing).hourlyPrice ? getVehiclesPrices(listing).hourlyPrice : '');
        setDaily(getVehiclesPrices(listing).dailyPrice ? getVehiclesPrices(listing).dailyPrice : '');
        setWeekly(getVehiclesPrices(listing).weeklyPrice ? getVehiclesPrices(listing).weeklyPrice : '');
        setMonthly(getVehiclesPrices(listing).monthlyPrice ? getVehiclesPrices(listing).monthlyPrice : '');
        setTruckHourly(getVehiclesPrices(listing).truckHourlyPrice ? getVehiclesPrices(listing).truckHourlyPrice : '');
        setTruckDaily(getVehiclesPrices(listing).truckDailyPrice ? getVehiclesPrices(listing).truckDailyPrice : '');
        setTruckWeekly(getVehiclesPrices(listing).truckWeeklyPrice ? getVehiclesPrices(listing).truckWeeklyPrice : '');
        setTruckMonthly(getVehiclesPrices(listing).truckMonthlyPrice ? getVehiclesPrices(listing).truckMonthlyPrice : '');
        setTrailerHourly(getVehiclesPrices(listing).trailerHourlyPrice ? getVehiclesPrices(listing).trailerHourlyPrice : '');
        setTrailerDaily(getVehiclesPrices(listing).trailerDailyPrice ? getVehiclesPrices(listing).trailerDailyPrice : '');
        setTrailerWeekly(getVehiclesPrices(listing).trailerWeeklyPrice ? getVehiclesPrices(listing).trailerWeeklyPrice : '');
        setTrailerMonthly(getVehiclesPrices(listing).trailerMonthlyPrice ? getVehiclesPrices(listing).trailerMonthlyPrice : '');
        setTruckTrailerHourly(getVehiclesPrices(listing).truckTrailerHourlyPrice ? getVehiclesPrices(listing).truckTrailerHourlyPrice : '');
        setTruckTrailerDaily(getVehiclesPrices(listing).truckTrailerDailyPrice ? getVehiclesPrices(listing).truckTrailerDailyPrice : '');
        setTruckTrailerWeekly(getVehiclesPrices(listing).truckTrailerWeeklyPrice ? getVehiclesPrices(listing).truckTrailerWeeklyPrice : '');
        setTruckTrailerMonthly(getVehiclesPrices(listing).truckTrailerMonthlyPrice ? getVehiclesPrices(listing).truckTrailerMonthlyPrice : '');
      }
    }
  }, [openEditListing, listing]);

  const onPriceChange = (e, fn) => {
    const newValue = e.target.value
    const regex = /^[1-9]\d*(\.)?(\d{1,2})?$/
    const isValidPrice = regex.test(newValue)

    if (isValidPrice || e.target.value === '') {
      fn(e.target.value)
    }
  };

  const onLatLngChange = (e, fn) => {
    const newValue = e.target.value
    const regex = /^(-?[0-9]\d*(\.)?(\d{1,6})?)$/
    const isValidValue = regex.test(newValue)

    if (isValidValue || e.target.value === '' || e.target.value === '-') {
      fn(e.target.value)
    }
  };

  const onBookingsChange = (e) => {
    const checked = e.target.checked;
    const value = +e.target.value;

    if (checked) {
      setEnabledBookings((prev) => [...prev, value]);
    } else {
      setEnabledBookings((prev) => prev.filter((el) => el !== value));
    }
  };

  const isNextDisabled = () => {
    let isEmpty = false;
    // Check trucks
    const trucksFilled = truckHourly && truckDaily && truckWeekly && truckMonthly;
    const trucksFilledValues = [truckHourly, truckDaily, truckWeekly, truckMonthly].filter(v => v);
    const trucksPresent = truckHourly || truckDaily || truckWeekly || truckMonthly;
    // Check trailers
    const trailersFilled = trailerHourly && trailerDaily && trailerWeekly && trailerMonthly;
    const trailersFilledValues = [trailerHourly, trailerDaily, trailerWeekly, trailerMonthly].filter(v => v);
    const trailersPresent = trailerHourly || trailerDaily || trailerWeekly || trailerMonthly;
    // Check truck and trailer combinations
    const truckTrailersFilled = truckTrailerHourly && truckTrailerDaily && truckTrailerWeekly && truckTrailerMonthly;
    const truckTrailersFilledValues = [truckTrailerHourly, truckTrailerDaily, truckTrailerWeekly, truckTrailerMonthly].filter(v => v);
    const truckTrailersPresent = truckTrailerHourly || truckTrailerDaily || truckTrailerWeekly || truckTrailerMonthly;

    if (!separatePrices) {
      if (
        (enabledBookings.indexOf(1) > -1 && !hourly) ||
        (enabledBookings.indexOf(2) > -1 && !daily) ||
        (enabledBookings.indexOf(3) > -1 && !weekly) ||
        (enabledBookings.indexOf(4) > -1 && !monthly)
      ) {
        isEmpty = true;
      }
    }
    else {
      if(!trucksPresent && !trailersPresent && !truckTrailersPresent) {
        isEmpty = true;
      }
    }
    return separatePrices ? isEmpty : isEmpty || !enabledBookings.length;
  };  
  
  const Save = () => {
    setLoading(true)
    let prices = [];

    const addPrice = (type, value, vehicleType) => {
      prices.push({ type, price: value, vehicle_type: vehicleType });
    };

    if (enabledBookings.indexOf(1) > -1) {
      if (!separatePrices) {
        addPrice("hour", (+hourly * 100)?.toFixed(0), 0);
        addPrice("hour", 0, 1);
        addPrice("hour", 0, 2);
        addPrice("hour", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("hour", 0, 0);
          addPrice("hour", (+truckHourly * 100)?.toFixed(0), 1);
          addPrice("hour", 0, 2);
          addPrice("hour", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("hour", 0, 0);
          addPrice("hour", 0, 1);
          addPrice("hour", (+trailerHourly * 100)?.toFixed(0), 2);
          addPrice("hour", 0, 3);
        } else {
          addPrice("hour", 0, 0);
          addPrice("hour", (+truckHourly * 100)?.toFixed(0), 1);
          addPrice("hour", (+trailerHourly * 100)?.toFixed(0), 2);
          addPrice("hour", (+truckTrailerHourly * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("hour", 0, 0);
      addPrice("hour", 0, 1);
      addPrice("hour", 0, 2);
      addPrice("hour", 0, 3);
    }

    if (enabledBookings.indexOf(2) > -1) {
      if (!separatePrices) {
        addPrice("day", (+daily * 100)?.toFixed(0), 0);
        addPrice("day", 0, 1);
        addPrice("day", 0, 2);
        addPrice("day", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("day", 0, 0);
          addPrice("day", (+truckDaily * 100)?.toFixed(0), 1);
          addPrice("day", 0, 2);
          addPrice("day", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("day", 0, 0);
          addPrice("day", 0, 1);
          addPrice("day", (+trailerDaily * 100)?.toFixed(0), 2);
          addPrice("day", 0, 3);
        } else {
          addPrice("day", 0, 0);
          addPrice("day", (+truckDaily * 100)?.toFixed(0), 1);
          addPrice("day", (+trailerDaily * 100)?.toFixed(0), 2);
          addPrice("day", (+truckTrailerDaily * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("day", 0, 0);
      addPrice("day", 0, 1);
      addPrice("day", 0, 2);
      addPrice("day", 0, 3);
    }

    if (enabledBookings.indexOf(3) > -1) {
      if (!separatePrices) {
        addPrice("week", (+weekly * 100)?.toFixed(0), 0);
        addPrice("week", 0, 1);
        addPrice("week", 0, 2);
        addPrice("week", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("week", 0, 0);
          addPrice("week", (+truckWeekly * 100)?.toFixed(0), 1);
          addPrice("week", 0, 2);
          addPrice("week", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("week", 0, 0);
          addPrice("week", 0, 1);
          addPrice("week", (+trailerWeekly * 100)?.toFixed(0), 2);
          addPrice("week", 0, 3);
        } else {
          addPrice("week", 0, 0);
          addPrice("week", (+truckWeekly * 100)?.toFixed(0), 1);
          addPrice("week", (+trailerWeekly * 100)?.toFixed(0), 2);
          addPrice("week", (+truckTrailerWeekly * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("week", 0, 0);
      addPrice("week", 0, 1);
      addPrice("week", 0, 2);
      addPrice("week", 0, 3);
    }

    if (enabledBookings.indexOf(4) > -1) {
      if (!separatePrices) {
        addPrice("month", (+monthly * 100)?.toFixed(0), 0);
        addPrice("month", 0, 1);
        addPrice("month", 0, 2);
        addPrice("month", 0, 3);
      } else {
        if (trucksAllowed && !trailersAllowed) {
          addPrice("month", 0, 0);
          addPrice("month", (+truckMonthly * 100)?.toFixed(0), 1);
          addPrice("month", 0, 2);
          addPrice("month", 0, 3);
        } else if (trailersAllowed && !trucksAllowed) {
          addPrice("month", 0, 0);
          addPrice("month", 0, 1);
          addPrice("month", (+trailerMonthly * 100)?.toFixed(0), 2);
          addPrice("month", 0, 3);
        } else {
          addPrice("month", 0, 0);
          addPrice("month", (+truckMonthly * 100)?.toFixed(0), 1);
          addPrice("month", (+trailerMonthly * 100)?.toFixed(0), 2);
          addPrice("month", (+truckTrailerMonthly * 100)?.toFixed(0), 3);
        }
      }
    } else {
      addPrice("month", 0, 0);
      addPrice("month", 0, 1);
      addPrice("month", 0, 2);
      addPrice("month", 0, 3);
    }

    let terms = tnc
    // if (optionalTerms && tnc) {
    //   terms = terms.substring(3, terms.length - 4);
    // }
    const openUTCTime = moment.tz(`${date} ${openTime}`, listing?.timezone_id).utc().format("HH:mm:00")
    const closeUTCTime = moment.tz(`${date} ${closeTime}`, listing?.timezone_id).utc().format("HH:mm:00")
    
    axios.put(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}`, {
      title: name,
      description: description,
      phone: phone,
      billing_phone: billingPhone,
      notes: note ? noteRef?.current?.unprivilegedEditor.getText() : note,
      signs: signs,
      vehicle_type_allowed: trucksAllowed ? 1 : trailersAllowed ? 2 : 0,
      apt: apt,
      seats: tempSeats,
      amenities: amenities,
      lat: lat,
      lng: lng,
      state: state,
      city: city,
      zip_code: address ? extractZipCode(address) : '-1',
      address: address,
      details: details,
      full_address: address,
      is_zero: disabledAllSeats,
      open_time: openTime ? openTime : null,
      close_time: closeTime ? closeTime : null,
      video,
      tnc: optionalTerms ? terms : null,
      is_qr_only: isQr,
      ...(isQr && (listing.status === 0 || listing.status === 4) && { textcode: textCode }),
    })
      .then((res) => {
        axios.put(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/price`, { prices })
          .then((res) => {
            toast.success("Updated Successfully");
            setSelectedListingId();
            setOpenEditListing(false);
            getListing();
            setLoading(false);
          })
          .catch((err) => {
            toast.error("Error Updating Prices");
            setLoading(false);
          });
      })
      .catch((err) => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Error Updating");
        }
        setLoading(false)
      });
  };

  const EditByPropertyGuard = () => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}`, {
        seats: tempSeats,
        is_zero: disabledAllSeats,
      })
      .then((res) => {
        getListing();
        toast.success("Seats Updated Successfully");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Something went wrong, please try again');
        }
      });
  }



  const ChangeAvtar = (id) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}`, {
        avatar: id,
      })
      .then((res) => {
        getListing();
        toast("Default Picture Updated", ToastStyle);
      })
      .catch((err) => {
        toast("Error Changing Default Picture", ToastStyle);
      });
  };

  const DeleteImage = (id, file) => {
    if (file === avatar) {
      toast("Profile Picture Cannot be Deleted", ToastStyle);
    } else {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/featured_images/${id}`
        )
        .then((res) => {
          toast("Picture Deleted Successfully", ToastStyle);
          getListing();
        })
        .catch((err) => {
          toast("Atleast One Image is Required", ToastStyle);
        });
    }
  };

  const fileSelectHandler = (e) => {
    if (images?.length < 8) {
      const files = [...e.target.files];
      const formData = new FormData();

      if (files.length) {
        for (let el of files) {
          formData.append("images", el, el.name);
        }
      }
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/listing/${selectedListingId}`,
          formData
        )
        .then((res) => {
          toast("Images Added Successfully", ToastStyle);
          getListing();
        })
        .catch((err) => {
          toast("Error Adding Images", ToastStyle);
        });
    } else {
      toast("Can't Add More Images max 8 is allowed", ToastStyle);
    }
  };



  //   const handleSetSeats = () => {
  //     if (selectedSeats) {
  //         setCalendarData([
  //             ...calendarData,
  //             {
  //                 title: `${selectedSeats}`,
  //                 start: showModal.date,
  //                 backgroundColor: '#e6e6e6',
  //                 borderColor: 'transparent',
  //                 textColor: '#000',
  //                 display: 'background',
  //             },
  //         ])
  //         setShowModal({
  //             status: false,
  //             date: ''
  //         })
  //         setSelectedSeats()
  //     }
  // }


  const handleSetSeats = () => {
    if (selectedSeats) {
      setLoading(true)
      axios.patch(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/seats?date=${showModal.date}&seats=${+selectedSeats}`).then(() => {
        setCalendarData([
          ...calendarData,
          {
            title: `${selectedSeats}`,
            start: showModal.date,
            backgroundColor: '#e6e6e6',
            borderColor: 'transparent',
            textColor: '#000',
            display: 'background',
          },
        ])
        setShowModal({
          status: false,
          date: ''
        })
        setSelectedSeats()
        toast("Exceptional Seats added successfully on the selected date", ToastStyle);
        setLoading(false)
      }).catch((err) => {
        if (err?.response?.data?.message?.includes('already booked')) {
          toast(`Can't set seats lower than the ${bookingsData.find((el) => el.date === showModal.date)?.seats} booked seats on the selected date`, ToastStyle);
          setLoading(false)
        } else {
          toast('Something went wrong, please try again', ToastStyle);
          setLoading(false)
        }
      })
    }
  }

  useEffect(() => {
    if (selectedListingId && seats) {
      setLoading(true)
      axios.get(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/seats?start_date=${currentMonth}&end_date=${moment(currentMonth).endOf('month').format('YYYY-MM-DD')}`).then(res => {
          const allSeats = Object.entries(res.data.seats).map(([key, value]) => {
            return {
              date: key,
              seats: value,
              booked: Object.keys(res.data.bookings).find(el => el === key) && res.data.bookings[key] > 0 ? true : false,
              exception: res.data.exceptions.some(el => el === key)
            }
          })
          const allBookings = Object.entries(res.data.bookings).map(([key, value]) => {
            return {
              date: key,
              seats: value,
              booked: true
            }
          })
          setBookingsData(allBookings)

          setCalendarData(allSeats.map((e) => {
            return {
                ...e,
                start: e.date,
                title: +e.seats,
                backgroundColor: e.booked ? '#bef5d4' : e.exception ? '#e6e6e6' : '#fff',
                display: 'background',
                booked: e.booked
            }
        }))
        setLoading(false)
      })
    }
  }, [calendarData.length, currentMonth, seats])

  const onDisableAllSeats = (type) => {
    axios.patch(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/disable?is_zero=${type}&disable_reason=${disableAllSeatsNote}`).then(() => {
      toast(`All Seats ${!disabledAllSeats ? 'Disabled  successfully' : 'Reverted Back Succesfully'}`, ToastStyle)
      setDisabledAllSeats(type)
      setDisableAllSeatsNote('')
      getListing()
      setShowModal({ status: false, date: '' })
    }).catch((err) => {
      if (err?.response?.data?.message) {
        toast(err?.response?.data?.message, ToastStyle)
      }else{
        toast('Something went wrong, please try again', ToastStyle)
      }
    })
  }

  const min = bookingsData?.find((e) => e.date === showModal.date) ? bookingsData.find((el) => el.date === showModal.date)?.seats : 0
  const removeTimeLimits = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/listing/${selectedListingId}/timing`)
      .then(res => {
        setOpenTime('')
        setCloseTime('')
      })
  }

const autoFixName = (revert) => {
  if(city && state && details && !revert){
    let vehicleName;
    let allowedVehiclesIds = listing.prices.filter((price) => price.plan_duration !== 5).map((price) => price.vehicle_type)
    if (allowedVehiclesIds.includes(1) && allowedVehiclesIds.includes(2)) {
      vehicleName = 'Truck and Trailer'
    } else if (allowedVehiclesIds.includes(1)) {
      vehicleName = 'Truck'
    } else if (allowedVehiclesIds.includes(2)) {
      vehicleName = 'Trailer'
    } else {
      vehicleName = 'Truck and Trailer'
    }
    let updatedName = `${listing?.city}, ${stateList.find(state => state.name === listing?.state)?.abbreviation || listing?.state} ${vehicleName} Parking on ${listing?.details}`
      setName(updatedName)
      setGeneratedName(true)
  }else{
    setName(listing?.title)
    setGeneratedName(false)
  }
}


  return (
    <>
      <div className="kt_body">
          <>
            <div style={{ height: '0' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: GOOGLE_MAPS_API_KEY,
                  language: 'en',
                  region: 'US'
                }}
                defaultCenter={{ lat: 38.6311966, lng: -98.1633745 }}
                defaultZoom={4}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              />
            </div>
            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <div className="d-flex justify-content-between">
              <label className="required form-label fs-6 fw-bold">Name of Space</label>
              {city && state && details &&<span className={`fw-bold fs-6 text-white ${generatedName ? 'bg-danger' : 'bg-dark'} rounded py-1 px-2 mb-1 pointer`} onClick={() => generatedName ? autoFixName(true) : autoFixName(false)}>{generatedName ? 'Revert' : 'Fix Listing Name ?'}</span>}
              </div>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="Space Name"
                value={name}
                onChange={e => {
                  const apostrophes = /['"‘’“”`]/g
                  let value = e.target.value
                  let result = value.replace(apostrophes, '\'')

                  const re = /^[a-z0-9-_,.:&' ]*$/i
                  if (!re.test(result)) {
                    return false
                  } else {
                    setName(result)
                  }
                }}
              />
            </div>
            }
            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label className="form-label fs-6 fw-bold">
                  Description of Space
                </label>
                <div>{description?.replace(/<[^>]+>/g, '')?.length || 0}/500</div>
              </div>
              {/* <TextareaAutosize
                minRows={2}
                maxRows={5}
                maxLength={500}
                style={{ resize: 'none' }}
                className="form-control form-control-solid w-100 "
                placeholder="Space Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              /> */}
              <ReactQuill
                theme="snow"
                className="editor"
                value={description}
                onChange={setDescription}
                modules={EditorModules}
              />
            </div>
            }

            {currentUser?.access_level !== 3 && (
              <>
                <div className="input-search mb-5 form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id='qr'
                    checked={isQr}
                    onChange={(e) => setIsQr(prev => !prev)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="qr"
                    style={{ fontWeight: "700", color: "#3f4254" }}
                  >
                    QR and Text2Pay Only
                  </label>
                </div>
                {isQr && (
                  <div className="input-search mb-5">
                    <label className="form-label fs-6 fw-bold">Text2Pay Code</label>
                    <input
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control form-control-solid w-100 "
                      placeholder="Enter Text2Pay Code"
                      value={textCode}
                      onChange={(e) => setTextCode(e.target.value)}
                      readOnly={listing.status !== 0 && listing.status !== 4}
                      disabled={listing.status !== 0 && listing.status !== 4}
                    />
                  </div>
                )}
              </>
            )}

            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">
                Contact Phone Number
              </label>
              {/* <input
                type="number"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="Phone Number"
                value={phone}
                onChange={(e) =>
                  setPhone(e.target.value.replace(/^\d{10}/g, ""))
                }
              /> */}
              <PatternFormat
                format={"+1 (###) ###-####"}
                className={"form-control form-control-solid mb-3 mb-lg-0"}
                value={phone}
                onValueChange={(values) => setPhone(values.value)}
                placeholder={"Phone Number"}

              />
            </div>
            }
            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">
                Billing Phone Number
              </label>
              <PatternFormat
                format={"+1 (###) ###-####"}
                className={"form-control form-control-solid mb-3 mb-lg-0"}
                value={billingPhone}
                onValueChange={(values) => setBillingPhone(values.value)}
                placeholder={"Billing Phone Number"}

              />
            </div>
            }
            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">
                Important information sent to the trucker AFTER paying for a
                space
              </label>
              <ReactQuill 
                ref={noteRef}
                theme="snow"
                className="editor"
                value={note}
                onChange={setNote}
                placeholder="Important information sent to the trucker AFTER paying for a space"
                modules={{
                  toolbar: false
                }}
                  />
            </div>
            }
            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">No of Signs</label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="No. Of Signs"
                value={signs}
                onChange={(e) =>
                  setSigns(e.target.value.replace(/[^0-9.]/g, ""))
                }
              />
            </div>
            }
            {/* vehicle type allowed hidden */}

            {/* {currentUser?.access_level !== 3 && (
              <div className="input-search mb-5 form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="2"
                  id="trucksAllowed"
                  checked={trucksAllowed}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSeparatePrices(true)
                      setVehicleTypeActive('truck')
                      setTrucksAllowed(true)
                      if (trailersAllowed) {
                        setTrailersAllowed(false)
                      }
                    } else {
                      setTrucksAllowed(false)
                    }
                  }}
                />
                <label
                  className="form-check-label"
                  for="trucksAllowed"
                  style={{ fontWeight: "700", color: "#3f4254" }}
                >
                  Only Trucks Allowed
                </label>
              </div>
            )}
            {currentUser?.access_level !== 3 && (
              <div className="input-search mb-5 form-check form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="2"
                  id="trailersAllowed"
                  checked={trailersAllowed}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSeparatePrices(true)
                      setVehicleTypeActive('trailer')
                      setTrailersAllowed(true)
                      if (trucksAllowed) {
                        setTrucksAllowed(false)
                      }
                    } else {
                      setTrailersAllowed(false)
                    }
                  }}
                />
                <label
                  className="form-check-label"
                  for="trailersAllowed"
                  style={{ fontWeight: "700", color: "#3f4254" }}
                >
                  Only Trailers Allowed
                </label>
              </div>
            )} */}

            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">Amenities</label>
              <div
                className="mb-10 mt-5"
                style={{
                  display: "grid",
                  gap: "25px",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                {
                  amenitiesList.map((el, index) => (
                    <div className="form-check form-check-custom form-check-solid" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={el.id}
                        id="flexCheckChecked"
                        checked={amenities?.indexOf(el.id) > -1}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const value = e.target.value.split(",");
                          if (checked) {
                            setAmenities((prev) => [...prev, +e.target.value]);
                          } else {
                            setAmenities((prev) =>
                              prev.filter(
                                (el) => el !== +e.target.value
                              )
                            );
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        // for="flexCheckChecked"
                        style={{ fontWeight: "700", color: "#3f4254" }}
                      >
                        {el.title}
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>
            }
            {currentUser?.access_level !== 3 && <div className='input-search mb-5'>
              <label className="form-label fs-6 fw-bold">Address</label>
              <input
                type="search"
                className="form-control form-control-solid w-100 "
                placeholder="123 Example Street"
                value={address}
                ref={inputRef}
                onChange={(e) => setAddress(e.target.value)}
                readOnly={listing.status !== 0 && listing.status !== 4}
                disabled={(listing.status !== 0 && listing.status !== 4) || !isMapLoaded}
              />
              {(listing.status !== 0 && listing.status !== 4) && (
                <div style={{ fontStyle: 'italic', marginTop: '4px' }}>Address change is disabled for SEO reasons, please reach tech team for emergency updates</div>
              )}
            </div>
            }
             {currentUser?.access_level !== 3 && <div className='input-search mb-5'>
              <div className="form-check form-check-custom form-check-solid mb-2 justify-content-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="latlng"
                  checked={manualLatLng}
                  onChange={() => {
                    setManualLatLng(!manualLatLng)
                  }}
                />
                <label
                  className="form-check-label"
                  for="latlng"
                  style={{ fontWeight: "700", color: "#3f4254" }}
                >
                Manually update Latitude and Longitude
                </label>
              </div>
              <div className="d-flex gap-2">  
              <div className="d-flex flex-column w-100 gap-2">
                <span style={{ fontWeight: "600" }}>Latitude</span>
                <input
                  className="form-control form-control-solid"
                  placeholder="Enter Latitude"
                  value={lat}
                  disabled={!manualLatLng}
                  onChange={(e) => {
                    onLatLngChange(e, setLat)
                  }}
                  onBlur={(e) => {
                    if(!e.target.value){
                      setLat(listing?.lat)
                    }
                  }}
                />
              </div>
              <div className="d-flex flex-column w-100 gap-2">
                <span style={{ fontWeight: "600" }}>Longitude</span>
                <input
                  className="form-control form-control-solid"
                  placeholder="Enter Longitude"
                  value={lng}
                  disabled={!manualLatLng}
                  onChange={(e) => {
                    onLatLngChange(e, setLng)
                  }}
                  onBlur={(e) => {
                    if(!e.target.value){
                      setLng(listing?.lng)
                    }
                  }}
                />
              </div>
              </div>
            </div>
            }
            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <div className="d-flex justify-content-between">               
              <label className="required form-label fs-6 fw-bold">Pricing</label>
             <div className="form-check form-check-custom form-check-solid mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="separatePrices"
                  checked={separatePrices}
                  disabled={trucksAllowed || trailersAllowed}
                  onChange={() => {
                    if(!separatePrices){
                      setEnabledBookings([1,2,3,4])
                    }else{
                      setEnabledBookings(
                        listing.prices
                          .filter((el) => el.price)
                          .map((el) => el.plan_duration)
                      );
                    }
                    setSeparatePrices(!separatePrices)
                  }}
                />
                <label
                  className="form-check-label"
                  for="separatePrices"
                  style={{ fontWeight: "700", color: "#3f4254" }}
                >
                 Enable Separate Prices
                </label>
              </div>
              </div>
            {/* {separatePrices && 
            <div className="p-4 bg-light rounded">
              <label className="form-label fs-6 fw-bold text-danger">Note:</label>
              <h6>Add prices for atleast one vehicle type for all the booking types.</h6>
              <h6>Leave the prices field blank for all the booking types for that vehicle which you don't want to be added.</h6>   
            </div>
            }    */}
             {separatePrices ?
             <>
             <div className="mt-2 d-flex">
                <button className={`px-10 btn bg-light border border-light rounded-0 ${vehicleTypeActive === 'truck' && 'vehicle-active rounded'}`}
                  // disabled={trailersAllowed}
                  onClick={() => {
                    setVehicleTypeActive('truck')
                  }}
                >Truck</button>
                <button className={`px-10 btn bg-light border border-light rounded-0  ${vehicleTypeActive === 'trailer' && 'vehicle-active rounded'}`}
                // disabled={trucksAllowed}
                  onClick={() => {
                    setVehicleTypeActive('trailer')
                  }}
                >Trailer</button>
              <button className={`px-10 btn bg-light border border-light rounded-0  ${vehicleTypeActive === 'trucktrailer' && 'vehicle-active rounded'}`}
                // disabled={trucksAllowed || trailersAllowed}
                  onClick={() => {
                    setVehicleTypeActive('trucktrailer')
                  }}
                >Truck + Trailer</button>
              </div>
              <div
                className="mb-10 mt-5"
                style={{
                  display: "grid",
                  gap: "25px",
                  gridTemplateColumns: "1fr",
                }}
              >
                <div>
                  {/* <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      id="hourly"
                      checked={enabledBookings.indexOf(1) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="hourly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable hourly bookings
                    </label>
                  </div> */}
                    <>
                      <span style={{ fontWeight: "600" }}>{vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per hour</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Hour"
                        value={vehicleTypeActive === 'truck' ? truckHourly : vehicleTypeActive === 'trailer' ? trailerHourly : truckTrailerHourly}
                        onChange={(e) => {
                          vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckHourly) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerHourly) : onPriceChange(e, setTruckTrailerHourly)
                        }}
                      />
                    </>
                </div>
                <div>
                  {/* <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="2"
                      id="daily"
                      checked={enabledBookings.indexOf(2) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="daily"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable daily bookings
                    </label>
                  </div> */}
                    <>
                      <span style={{ fontWeight: "600" }}>{vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per day</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Day"
                        value={vehicleTypeActive === 'truck' ? truckDaily : vehicleTypeActive === 'trailer' ? trailerDaily : truckTrailerDaily}
                        onChange={(e) => {
                          vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckDaily) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerDaily) : onPriceChange(e, setTruckTrailerDaily)
                        }}
                      />
                    </>
                </div>
                <div>
                  {/* <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="3"
                      id="weekly"
                      checked={enabledBookings.indexOf(3) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="weekly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable weekly bookings
                    </label>
                  </div> */}
                    <>
                      <span style={{ fontWeight: "600" }}>{vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per week</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Week"
                        value={vehicleTypeActive === 'truck' ? truckWeekly : vehicleTypeActive === 'trailer' ? trailerWeekly : truckTrailerWeekly}
                        onChange={(e) => {
                          vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckWeekly) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerWeekly) : onPriceChange(e, setTruckTrailerWeekly)
                        }}
                      />
                    </>
                </div>
                <div>
                  {/* <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="4"
                      id="monthly"
                      checked={enabledBookings.indexOf(4) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="monthly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable monthly bookings
                    </label>
                  </div> */}
                    <>
                      <span style={{ fontWeight: "600" }}>
                      {vehicleTypeActive === 'trucktrailer' ? 'Truck + Trailer' : vehicleTypeActive === 'truck' ? 'Truck' : 'Trailer'} Price per month (30 days)
                      </span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Month"
                        value={vehicleTypeActive === 'truck' ? truckMonthly : vehicleTypeActive === 'trailer' ? trailerMonthly : truckTrailerMonthly}
                        onChange={(e) => {
                          vehicleTypeActive === 'truck' ? onPriceChange(e, setTruckMonthly) : vehicleTypeActive === 'trailer' ? onPriceChange(e, setTrailerMonthly) : onPriceChange(e, setTruckTrailerMonthly)
                        }}
                      />
                    </>
                </div>
              </div>
              </>
              :
              <div
                className="mb-10 mt-5"
                style={{
                  display: "grid",
                  gap: "25px",
                  gridTemplateColumns: "1fr",
                }}
              >
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="1"
                      id="hourly"
                      checked={enabledBookings.indexOf(1) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="hourly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable hourly bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(1) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>Price per hour</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Hour"
                        value={hourly}
                        onChange={(e) => onPriceChange(e, setHourly)}
                      />
                    </>
                  )}
                </div>
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="2"
                      id="daily"
                      checked={enabledBookings.indexOf(2) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="daily"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable daily bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(2) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>Price per day</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Day"
                        value={daily}
                        onChange={(e) => onPriceChange(e, setDaily)}
                      />
                    </>
                  )}
                </div>
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="3"
                      id="weekly"
                      checked={enabledBookings.indexOf(3) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="weekly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable weekly bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(3) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>Price per week</span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Week"
                        value={weekly}
                        onChange={(e) => onPriceChange(e, setWeekly)}
                      />
                    </>
                  )}
                </div>
                <div>
                  <div className="form-check form-check-custom form-check-solid mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="4"
                      id="monthly"
                      checked={enabledBookings.indexOf(4) > -1}
                      onChange={onBookingsChange}
                    />
                    <label
                      className="form-check-label"
                      for="monthly"
                      style={{ fontWeight: "700", color: "#3f4254" }}
                    >
                      Enable monthly bookings
                    </label>
                  </div>
                  {enabledBookings.indexOf(4) > -1 && (
                    <>
                      <span style={{ fontWeight: "600" }}>
                        Price per month (30 days)
                      </span>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-100 mt-2 "
                        placeholder="Price Per Month"
                        value={monthly}
                        onChange={(e) => onPriceChange(e, setMonthly)}
                      />
                    </>
                  )}
                </div>
              </div>
              }
            </div>
            }
            <Calendar
              setCalendarData={setCalendarData}
              calendarData={calendarData}
              setShowModal={setShowModal}
              spaceAvailable={seats}
              listingId={selectedListingId}
              setCurrentMonth={setCurrentMonth}
              isLoading={isLoading}
              setSeats={setSeats}
              tempSeats={tempSeats}
              disabledAllSeats={disabledAllSeats}
              setDisabledAllSeats={setDisabledAllSeats}
              onDisableAllSeats={onDisableAllSeats}
              haveAccess={currentUser?.access_level !== 3}
              restrictedHours={listing.restricted_hours}
              listing={listing}
            />
            <div className="input-search mb-5">
              <>
                <label className="required form-label fs-6 fw-bold">
                  Spaces Available
                </label>
                {currentUser?.access_level == 3 && <span style={{ color: "red", marginLeft: '5px' }}>Not allowed to edit</span>}
              </>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="Spaces Available"
                value={tempSeats}
                disabled={currentUser?.access_level == 3}
                onChange={(e) => {
                  setSeats(e.target.value.replace(/[^0-9]/g, ""))
                  setTempSeats(e.target.value.replace(/[^0-9]/g, ""))
                }
                }
                min={1}
              />
            </div>
            {currentUser?.access_level !== 3 && <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">
                Open Time (optional)
              </label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={openTime}
                onChange={(e) => setOpenTime(e.target.value)}
              >
                <option value="">Select open time</option>
                {hours.map((el) => (
                  <option
                    value={el.value}
                    key={el.value}
                  >
                    {el.text}
                  </option>
                ))}
              </select>
            </div>
            }
            {currentUser?.access_level !== 3 && <div className="fv-row mb-5">
              <label className="fw-bold fs-6 mb-2">
                Close Time (optional)
              </label>
              <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="two-step"
                data-hide-search="true"
                value={closeTime}
                onChange={(e) => setCloseTime(e.target.value)}
              >
                <option value="">Select close time</option>
                {hours.map((el) => (
                  <option
                    value={el.value}
                    key={el.value}
                  >
                    {el.text}
                  </option>
                ))}
              </select>
            </div>
            }
            {(openTime || closeTime) && (
              <button
                className="btn mb-5"
                style={{ backgroundColor: "#ffa459", color: "#fff" }}
                onClick={removeTimeLimits}
              >
                <span className="indicator-label">Remove time limits</span>
              </button>
            )}
            {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">
                Youtube Video
              </label>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100"
                placeholder="Enter Youtube Video Link"
                value={video}
                onChange={(e) => setVideo(e.target.value)}
              />
            </div>
            }

            {currentUser?.access_level !== 3 && <div className="input-search mb-5 form-check form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                id='optionalTerms'
                checked={optionalTerms}
                onChange={() => setOptionalTerms(prev => !prev)}
              />
              <label
                className="form-check-label"
                htmlFor="optionalTerms"
                style={{ fontWeight: "700", color: "#3f4254" }}
              >
                Optional Terms and Conditions
              </label>
            </div>
            }
            {optionalTerms && (
              <div>
                <ReactQuill
                  theme="snow"
                  value={tnc}
                  onChange={setTnc}
                  modules={EditorModules}
                />
              </div>
            )}
            {/* {currentUser?.access_level !== 3 && <div className="input-search mb-5">
              <label className="form-label fs-1 fw-bold">Photos</label>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                {images &&
                  images.map((el, i, arr) => (
                    <div
                      key={el.id}
                      style={{
                        position: "relative",
                        width: "150px",
                        height: "150px",
                        outline:
                          avatar && el.file === avatar
                            ? "4px solid #ffa459"
                            : i == 0 && !avatar && "4px solid #ffa459",
                      }}
                    >
                      <button
                        style={{
                          position: "absolute",
                          top: el.file === avatar ? "-8px" : "-4px",
                          right: el.file === avatar ? "-8px" : "-4px",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: 0,
                          background: "#ffa459",
                          color: "#fff",
                          zIndex: 9,
                        }}
                        onClick={() => DeleteImage(el.asset_id, el.file)}
                      >
                        <i
                          className="fas fa-times"
                          style={{ color: "#fff" }}
                        ></i>
                      </button>
                      {el.file === avatar && (
                        <span
                          style={{
                            position: "absolute",
                            left: "2px",
                            top: "1px",
                            color: "#ffa459",
                            zIndex: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#ffa459"
                            height="1.2em"
                            width="1.5em"
                            viewBox="0 0 576 512"
                          >
                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                          </svg>{" "}
                          <span
                            style={{
                              color: "#fff",
                              marginLeft: "5px",
                              fontWeight: "700",
                            }}
                          >
                            Profile Picture
                          </span>
                        </span>
                      )}
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + '300:200:' + el.file.slice(1)}
                        style={{
                          width: "100%",
                          height: "100%",
                          filter: "opacity(0.8)",
                        }}
                        alt=""
                        onClick={() => {
                          setAvatar(el.file);
                          setAvatarId(el.id);
                          ChangeAvtar(el.id);
                        }}
                      />
                    </div>
                  ))}
              </div>
              <label
                style={{
                  width: "90%",
                  height: "240px",
                  border: "2px dashed #4a4a4a",
                  borderRadius: "2px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  rowGap: "6px",
                  margin: "16px 0 8px",
                  cursor: "pointer",
                }}
              >
                <div>+ Choose an image...</div>
                <span>.JPG, .GIF or .PNG. Max 20 MB</span>
                <input
                  type="file"
                  multiple
                  onChange={fileSelectHandler}
                  style={{ display: "none" }}
                  accept="image/jpg, image/jpeg, image/png, image/gif"
                />
              </label>
              Tip: Choose the top 2-3 photos (max 8) of your space, we recommend
              aerials and a photo of the entrance
            </div>
            } */}
          </>
      </div>

      <div className="text-center pt-5">
        <button
          type="reset"
          onClick={cancel}
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
        // disabled={formik.isSubmitting || isUserLoading}
        >
          {currentUser?.access_level !== 3 ? "Discard" : "Close"}
        </button>

        {currentUser?.access_level !== 3 && (
          <button
            type="submit"
            className="btn"
            style={{ backgroundColor: "#ffa459", color: "#fff" }}
            data-kt-users-modal-action="submit"
            disabled={
              !name ||
              isNextDisabled() ||
              !seats ||
              !address ||
              !city || 
              !state ||
              !lat ||
              !lng ||
              isLoading
            }
            onClick={Save}
          >
            <span className="indicator-label">Save Changes</span>
          </button>
        )
          // :
          //   currentUser?.access_level == 3 && haveAccess(currentUser, 'listing_edit') &&
          //   (
          //     <button
          //       type="submit"
          //       className="btn"
          //       style={{ backgroundColor: "#ffa459", color: "#fff" }}
          //       data-kt-users-modal-action="submit"
          //       disabled={
          //         !seats
          //       }
          //       onClick={EditByPropertyGuard}
          //     >
          //       <span className="indicator-label">Save Changes</span>
          //     </button>
          //   )
        }
      </div>
      {showModal.status && showModal.type === 'single' && (
        <div className="modal_wrapper">
          <div className="modal_container">
            <div className="input-search mb-5">
              <label className="form-label fs-6 fw-bold">
                Enter Seats Available for {' '}
                {moment(showModal.date).format("ddd DD MMM, YYYY")}
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                data-kt-user-table-filter="search"
                className="form-control form-control-solid w-100 "
                placeholder="1"
                value={selectedSeats}
                onChange={(e) => {

                  // if (calendarData?.find((e) => e.start === showModal.date)?.booked === true) {
                  //     if (+e.target.value < +seats - calendarData.find((e) => e.start === showModal.date).seats) {
                  //       setSelectedSeats(e.target.value)
                  //       // if(min){
                  //       //   if(+e.target.value >= +min || e.target.value === '') {
                  //       //     setSelectedSeats(e.target.value)
                  //       //   }
                  //       // }else{
                  //       //   setSelectedSeats(e.target.value)
                  //       // }
                  //     }
                  // }else {
                  if (+e.target.value < +seats) {
                    setSelectedSeats(e.target.value)
                  }
                  // }
                }}
              />
              {selectedSeats && +selectedSeats < +min && <p style={{ color: '#ed2626', marginTop: '10px', marginBottom: 0 }}>Enter More than {min} seats as these no of seats are already booked</p>}
            </div>
            <div className="modal_btn_container">
              <button
                className="btn"
                onClick={() => {
                  setShowModal({
                    status: false,
                    date: "",
                  })
                  setSelectedSeats()
                }
                }
                style={{ background: "#fff", color: "#ffa459" }}
              >
                Cancel
              </button>
              <button className="btn" onClick={handleSetSeats} disabled={!selectedSeats || selectedSeats && selectedSeats < min} style={{ background: "#ffa459", color: "#fff" }}>
                Set
              </button>
            </div>
          </div>
        </div>
      )}
        {showModal.status && showModal.type === 'every' && (
        <div className="modal_wrapper">
          <div className="modal_container">
            <div className="input-search mb-5">
            <h6 style={{ color: "#ed2626" }}>
             Note:You are required to leave a note in order to Disable All Seats
            </h6>
            <h6>(Min: 15 Characters)</h6>
              <textarea
                    className="form-control form-control-solid w-100 mt-4"
                    placeholder="Note (Min: 15 Characters)"
                    value={disableAllSeatsNote}
                    onChange={(e) => setDisableAllSeatsNote(e.target.value)}
              />
            </div>
            <div className="modal_btn_container">
              <button
                className="btn"
                onClick={() => {
                  setShowModal({
                    status: false,
                    date: "",
                  })    
                }
                }
                style={{ background: "#fff", color: "#ffa459" }}
              >
                Cancel
              </button>
              <button className="btn btn-danger" onClick={() => {
              onDisableAllSeats(true)
              }} disabled={(!disableAllSeatsNote || disableAllSeatsNote.length < 15)}>
                Disable All Seats
              </button>
            </div>
          </div>
        </div>
      )}
      {/* end::Actions */}
      {/* </form> */}
    </>
  );
};

export { ListingEditModalForm };
