import { KTIcon } from "../../../../_metronic/helpers";

const TimelineModalHeader = ({
  setOpenTimelineModal,
  user,
}) => {
  return (
    <div className="modal-header">
      {/* begin::Modal title */}
      <div>
      <h2 className="fw-bolder">{`View Stripe Logs for (${user?.first_name} ${user?.last_name})`}</h2>
      </div>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className="btn btn-icon btn-sm btn-active-icon-primary"
        data-kt-users-modal-action="close"
        onClick={() => {
          setOpenTimelineModal(false)
        }}
        style={{ cursor: "pointer" }}
      >
        <KTIcon iconName="cross" className="fs-1" />
      </div>
      {/* end::Close */}
    </div>
  );
};

export { TimelineModalHeader };
