import dayjs from "dayjs";
// import AmexIcon from "../assets/images/icons/amex.svg";
// import DiscoverIcon from "../assets/images/icons/discover.svg";
// import MastercardIcon from "../assets/images/icons/mastercard.svg";
// import VisaIcon from "../assets/images/icons/visa.svg";
import { toAbsoluteUrl } from "./index";

export const formatPhone = (phone) => {
  phone = phone?.replace(/[^\d]/g, "");

  if (phone?.length == 11) {
    return phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
  }
  else if (phone?.length == 10) {
    return phone?.replace(/(\d{3})(\d{3})(\d{4})/, "+1 ($1) $2-$3");
  }

  return null;
}

export const formatPrice = (price) => {
  if (!price) return ''
  let arr = price.toString().split("");
  arr.splice(-2, 0, ".");
  let res = "$" + arr.join("");
  if (res[1] === "-" && res[2] === ".") {
    res = res.split("");
    res.splice(2, 0, "0");
    res = res.join("");
  }
  if (res[1] === ".") {
    res = res.split("");
    res.splice(1, 0, "0");
    res = res.join("");
  }
  return res;
};

export const formatPriceNoDecimal = (price) => {
  let arr = price.toString().split("");
  arr.splice(-2, 2);
  return "$" + arr.join("");
};

export const getPlanDurationName = (id) => {
  switch (id) {
    case 1:
      return "Per hour";
    case 2:
      return "Per day";
    case 3:
      return "Per week";
    case 4:
      return "Per month";
    default:
      return;
  }
};

export const getEnd = (type, start, hours, days, weeks) => {
  if (type == 1) {
    return {
      day: dayjs(start).add(hours, "hour").format("dddd"),
      date: dayjs(start).add(hours, "hour").format("MMM DD"),
      full: dayjs(start).add(hours, "hour"),
    };
  }

  if (type == 2) {
    return {
      day: dayjs(start).add(days, "days").format("dddd"),
      date: dayjs(start).add(days, "days").format("MMM DD"),
      full: dayjs(start).add(days, "days"),
    };
  }

  if (type == 3) {
    return {
      day: dayjs(start).add(7 * weeks, "days").format("dddd"),
      date: dayjs(start).add(7 * weeks, "days").format("MMM DD"),
      full: dayjs(start).add(7 * weeks, "days"),
    };
  }

  if (type == 4) {
    return {
      day: dayjs(start).add(30, "days").format("dddd"),
      date: dayjs(start).add(30, "days").format("MMM DD"),
      full: dayjs(start).add(30, "days"),
    };
  }
};

export const getBrandIcon = (name) => {
  switch (name.toLowerCase()) {
    case "american express":
      return toAbsoluteUrl("/media/logos/amex.svg");
    case "discovery":
      return toAbsoluteUrl("/media/logos/discover.svg");
    case "mastercard":
      return toAbsoluteUrl("/media/logos/mastercard.svg");
    case "visa":
      return toAbsoluteUrl("/media/logos/visa.svg");
    default:
      return "";
  }

  // const BRAND_DINERS = 'diners'
  // const BRAND_JCB = 'jcb'
  // const BRAND_UNIONPAY = 'unionpay'
};

export const getTimelineEvent = (id, userType, customerName, listingTitle) => {
  switch (id) {
    case 1:
      if (userType === "owner") {
        return `${customerName} requested to book ${listingTitle}.`;
      } else {
        return `You requested to book ${listingTitle}.`;
      }
    case 2:
      if (userType === "owner") {
        return `${customerName} payment was confirmed.`;
      } else {
        return "Your payment was accepted.";
      }
    case 3:
      if (userType === "owner") {
        return `${customerName} clocked in.`;
      } else {
        return "You clocked in.";
      }
    case 4:
      if (userType === "owner") {
        return `${customerName} prolonged the subscription.`;
      } else {
        return "You prolonged the subscription.";
      }
    case 5:
      return "The booking was completed.";
    case 6:
      if (userType === "owner") {
        return "The booking was canceled by the customer.";
      } else {
        return "You canceled the booking.";
      }
    case 7:
      if (userType === "owner") {
        return "You canceled the booking.";
      } else {
        return "The booking was canceled by the parking owner.";
      }
    case 8:
      if (userType === "owner") {
        return "The booking was canceled by the platform administrator.";
      } else {
        return "The booking was canceled by the platform administrator.";
      }
  }
};

export const getBookingStatus = (id) => {
  switch (id) {
    case -1:
      return "deleted";
    case 0:
      // 'pending'
      return "created";
    case 1:
      // 'paid'
      return "accepted";
    case 2:
      // 'clock in'
      return "active";
    case 3:
      // 'clock out'
      return "completed";
    case 4:
      return "cancelled";
    case 5:
      return "declined";
    case 6:
      return "completed";
  }
};


export const listingLogs = (id) => {
  switch (id) {
    case 1:
      return "Listing is Created.";
    case 2:
      return "Price have been Updated.";
    case 3:
      return "Seats have been Updated.";
    case 4:
      return "Marked as Closed.";
    case 5:
      return "Marked as Open.";
    case 6:
      return "Status Changed.";
    case 7:
      return "Name have been Updated.";
    case 8:
      return "Disabled.";
    case 9:
      return "Enabled.";
    case 10:
      return "Amenities have been Updated.";
    case 11:
      return "Important Info have been Updated.";
    case 12:
      return "Admin Note Added.";
    case 13:
      return "Publish Request Sent.";
    case 14:
      return "Fees have been Updated.";
    case 15:
      return "QR and Text2Pay Only.";
    case 16:
      return "Photo(s) have been Removed.";
    case 17:
      return "New Photo(s) have been Added.";
    case 18:
      return "All Seats have been Disabled.";
    case 19:
      return "Stripe Connect have been Enabled.";
    case 20:
      return "Stripe Connect have been Reset.";
  }
}

export const userLogs = (id) => {
  switch (id) {
    case 1:
      return "Stripe Connect have been Reset.";
  }
}


export const getListingPrices = (listing = [], vehicleType = 3) => {
  let tempPrice = listing?.prices?.filter((price) => {
    return price.vehicle_type == vehicleType
  })
  if (tempPrice && tempPrice?.length > 0) {
    return tempPrice
  } else {
    return listing?.prices?.filter((price) => price.vehicle_type == 0)
  }
}




export const getVehiclesPrices = (listing = []) => {
  let hourlyPrice;
  let dailyPrice;
  let weeklyPrice;
  let monthlyPrice;
  let truckHourlyPrice;
  let truckDailyPrice;
  let truckWeeklyPrice;
  let truckMonthlyPrice;
  let trailerHourlyPrice;
  let trailerDailyPrice;
  let trailerWeeklyPrice;
  let trailerMonthlyPrice;
  let truckTrailerHourlyPrice;
  let truckTrailerDailyPrice;
  let truckTrailerWeeklyPrice;
  let truckTrailerMonthlyPrice;

  let hasSeperatePricing = false;

  listing?.prices?.forEach((el) => {
    if (el.vehicle_type > 1) {
      hasSeperatePricing = true;
    }
  });


  listing?.prices?.forEach((el, fn) => {
    switch (el.vehicle_type) {
      case 0: // All vehicles
        switch (el.plan_duration) {
          case 1: // Hourly
            hourlyPrice = el.price / 100;
            if (!hasSeperatePricing) {
              truckHourlyPrice = el.price / 100;
              trailerHourlyPrice = el.price / 100;
              truckTrailerHourlyPrice = el.price / 100;
            }
            break;
          case 2: // Daily
            dailyPrice = el.price / 100;
            if (!hasSeperatePricing) {
              truckDailyPrice = el.price / 100;
              trailerDailyPrice = el.price / 100;
              truckTrailerDailyPrice = el.price / 100;
            }
            break;
          case 3: // Weekly
            weeklyPrice = el.price / 100;
            if (!hasSeperatePricing) {
              truckWeeklyPrice = el.price / 100;
              trailerWeeklyPrice = el.price / 100;
              truckTrailerWeeklyPrice = el.price / 100;
            }
            break;
          case 4: // Monthly
            monthlyPrice = el.price / 100;
            if (!hasSeperatePricing) {
              truckMonthlyPrice = el.price / 100;
              trailerMonthlyPrice = el.price / 100;
              truckTrailerMonthlyPrice = el.price / 100;
            }
            break;
          default:
            break;
        }
        break;
      case 1: // Only truck
        switch (el.plan_duration) {
          case 1: // Hourly
            truckHourlyPrice = el.price / 100;
            break;
          case 2: // Daily
            truckDailyPrice = el.price / 100;
            break;
          case 3: // Weekly
            truckWeeklyPrice = el.price / 100;
            break;
          case 4: // Monthly
            truckMonthlyPrice = el.price / 100;
            break;
          default:
            break;
        }
        break;
      case 2: // Only trailer
        switch (el.plan_duration) {
          case 1: // Hourly
            trailerHourlyPrice = el.price / 100;
            break;
          case 2: // Daily
            trailerDailyPrice = el.price / 100;
            break;
          case 3: // Weekly
            trailerWeeklyPrice = el.price / 100;
            break;
          case 4: // Monthly
            trailerMonthlyPrice = el.price / 100;
            break;
          default:
            break;
        }
        break;
      case 3: // Truck and trailer
        switch (el.plan_duration) {
          case 1: // Hourly
            truckTrailerHourlyPrice = el.price / 100;
            break;
          case 2: // Daily
            truckTrailerDailyPrice = el.price / 100;
            break;
          case 3: // Weekly
            truckTrailerWeeklyPrice = el.price / 100;
            break;
          case 4: // Monthly
            truckTrailerMonthlyPrice = el.price / 100;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  });


  return {
    hourlyPrice,
    dailyPrice,
    weeklyPrice,
    monthlyPrice,
    truckHourlyPrice,
    truckDailyPrice,
    truckWeeklyPrice,
    truckMonthlyPrice,
    trailerHourlyPrice,
    trailerDailyPrice,
    trailerWeeklyPrice,
    trailerMonthlyPrice,
    truckTrailerHourlyPrice,
    truckTrailerDailyPrice,
    truckTrailerWeeklyPrice,
    truckTrailerMonthlyPrice,
  };

}


const getStatusColorAndText = (listing = {}, checkEdit = () => { }) => {
  switch (listing.status) {
    case 0:
      return { color: "#ffa459", text: "Pending Payment" };
    case 5:
      return { color: "#ed2626", text: "Declined" };
    case 7:
      return { color: "#ed2626", text: "Payment Failed" };
    case 4:
      if (listing.booking_type === 1 || listing.booking_type === 2 || listing.booking_type === 3) {
        return { color: "#ed2626", text: "Canceled Without Refund" };
      } else if (listing.booking_type === 4) {
        return { color: "#ed2626", text: "Canceled" };
      }
      break;
    case 9:
      return { color: "#ffa459", text: "Canceled With Refund" };
    case 8:
      return { color: "#ffa459", text: "Active And Not Renewed" };
    case 10:
      return { color: "#ffa459", text: "Refunded" };
    case 6:
      return { color: "#2ecc71", text: "Completed" };
    case 11:
      return { color: "#ed2626", text: "PAST DUE" };
    case 12:
      return { color: "#ed2626", text: "SUSPENDED" };
    default:
      const editStatus = checkEdit(listing.start_date, listing.clock_in, listing.end_date, listing?.timezone_id);
      return {
        color: editStatus === "In Progress" ? "#ffa459" : editStatus === "Upcoming" ? "#ed2626" : "#ffa459",
        text: editStatus === "Completed" && listing.status === 1 ? 'In Progress' : editStatus
      };
  }
};

export const BookingStatus = (listing = {}, checkEdit) => {
  const { color, text } = getStatusColorAndText(listing, checkEdit);

  return <span style={{ color }}>{text}</span>;
};

export const getBookingStatusText = (listing = {}) => {
  switch (listing.status) {
    case 0:
      return "PENDING PAYMENT";
    case 1:
      return "ACCEPTED";
    case 2:
      return "CLOCK IN";
    case 3:
      return "CLOCK OUT";
    case 4:
      if (listing.booking_type === 1 || listing.booking_type === 2 || listing.booking_type === 3) {
        return "CANCELED WITHOUT REFUND";
      } else if (listing.booking_type === 4) {
        return "CANCELLED"
      }
      break;
    case 5:
      return "DECLINED";
    case 6:
      return "COMPLETED";
    case 7:
      return "PAYMENT FAILED";
    case 8:
      return "ACTIVE & NOT RENEWED";
    case 9:
      return "CANCELED WITH REFUND";
    case 10:
      return "REFUNDED";
    case 11:
      return "PAST DUE";
    case 12:
      return "SUSPENDED";
  }
};


export const pointsStatus = (type, transactionType, status, reservationNumber) => {
  if (type === 'CR') {
    switch (status) {
      case 0:
        return {
          text: `points will be credited for ${transactionType === "booking" ? `booking ${reservationNumber} after it is completed` : `review on booking ${reservationNumber}`}.`,
          color: "#acacac"
        };
      case 1:
        return {
          text: `points have been credited for ${transactionType === "booking" ? `booking ${reservationNumber}` : `review on booking ${reservationNumber}`}.`,
          color: "#2ecc71"
        };
      case 2:
        return {
          text: `points have been consumed for ${transactionType === "booking" ? `booking ${reservationNumber}` : `review on booking ${reservationNumber}`}.`,
          color: "#ffa459"
        };
      case -1:
        return {
          text: `points have been expired.`,
          color: "#ed2626"
        };
    }
  } else if (type === 'DR') {
    return {
      text: `points have been spent for booking ${reservationNumber}.`,
      color: "#ed2626"
    }
  }
}

export const getColorByBookingStatus = (status) => {
  switch (status) {
    case 'progress':
      return "#ffa459";
    case 'upcoming':
      return "#009ef7";
    case 'cancelled':
      return "#ed2626";
    case 'not-renewing':
      return "#5e6278";
    case 'past_due':
      return "#ed2626";
    case 11:
      return "#ed2626";
    case 12:
      return "#ed2626";
    default:
      return "#2ecc71";
  }
}



export const generateURL = (baseUrl, queryParams) => {
  let url = `${baseUrl}`;

  const params = [];

  Object.keys(queryParams).forEach(key => {
    const value = queryParams[key];

    if (value) {
      params.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  if (params.length > 0) {
    url += '?' + params.join('&');
  }

  return url;
};

export const ReplaceAndCapitalize = (inputString) => {
  let capitalizedString = inputString.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return capitalizedString;
}

export const isSearchAndFilterApplied = (search, filter, result, ameneties = []) => {
  const isFilterFilled = Object.values(filter).some(value => !!value);
  const isAmenitiesFilled = ameneties && ameneties?.length > 0;
  if (search && isFilterFilled && isAmenitiesFilled) {
    return `Search, Filter & Amenities Applied: ${result} Results Found`;
  } else if (search && isFilterFilled) {
    return `Search & Filter Applied: ${result} Results Found`;
  } else if (isFilterFilled && isAmenitiesFilled) {
    return `Filter & Amenities Applied: ${result} Results Found`;
  } else if (search && isAmenitiesFilled) {
    return `Search & Amenities Applied: ${result} Results Found`;
  }
  else if (search) {
    return `Search Applied: ${result} Results Found`;
  } else if (isFilterFilled) {
    return `Filter Applied: ${result} Results Found`;
  } else if (isAmenitiesFilled) {
    return `Amenities Applied: ${result} Results Found`;
  }
  else {
    return null;
  }
}


export const ToastStyle = {
  position: "top-right",
  autoClose: 2500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark"
}


export const removeHtmlTags = (htmlString) => {
  return htmlString.replace(/<\/?[^>]+>/gi, '');
};


export const listingFieldStatus = [
  {
    label: 'Title',
    value: 1,
    textType: 'string'
  },
  {
    label: 'Description',
    value: 2,
    textType: 'html'
  },
  {
    label: 'Amenities',
    value: 3,
    textType: 'string'

  },
  {
    label: 'Seats',
    value: 4,
    textType: 'string'
  },
  {
    label: 'Important Information',
    value: 5,
    textType: 'string'
  },
  {
    label: 'Phone',
    value: 6,
    textType: 'phone'
  },
  {
    label: 'Status',
    value: 7,
    textType: 'string'
  },
  {
    label: 'Billing Phone',
    value: 10,
    textType: 'phone'
  },
  {
    label: 'Notification User Added',
    value: 13,
    textType: 'string'
  },
  {
    label: 'Notification User Updated',
    value: 14,
    textType: 'string'
  },
  {
    label: 'Notification User Removed',
    value: 15,
    textType: 'string'
  },
  {
    label: 'Hourly Price',
    value: 17,
    textType: 'price'
  },
  {
    label: 'Daily Price',
    value: 18,
    textType: 'price'
  },
  {
    label: 'Weekly Price',
    value: 19,
    textType: 'price'
  },
  {
    label: 'Monthly Price',
    value: 20,
    textType: 'price'
  },
  {
    label: 'Is QR Only',
    value: 21,
    textType: 'boolean'
  },
  {
    label: 'Text 2 Pay',
    value: 22,
    textType: 'string'
  },
  {
    label: 'Images Update',
    value: 23,
    textType: 'string'
  },
  {
    label: 'Closed',
    value: 8,
    textType: 'string'
  },
  {
    label: 'Opened',
    value: 9,
    textType: 'string'
  },
  {
    label: 'Open Timing',
    value: 11,
    textType: 'string'
  },
  {
    label: 'Close Timing',
    value: 12,
    textType: 'string'
  },
  {
    label: 'Video',
    value: 24,
    textType: 'string'
  },
  {
    label: 'Hourly Price Disabled',
    value: 25,
    textType: 'price'
  },
  {
    label: 'Daily Price Disabled',
    value: 26,
    textType: 'price'
  },
  {
    label: 'Weekly Price Disabled',
    value: 27,
    textType: 'price'
  },
  {
    label: 'Monthly Price Disabled',
    value: 28,
    textType: 'price'
  },

]


export const parseAddressFromHTMLString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const streetAddress = doc.querySelector('.street-address')?.textContent.trim() || null;
  const city = doc.querySelector('.locality')?.textContent.trim() || null;
  const state = doc.querySelector('.region')?.textContent.trim() || null;
  const country = doc.querySelector('.country-name')?.textContent.trim() || null;

  return {
    street: streetAddress,
    city: city,
    state: state,
    country: country
  };
}

export const daysOfWeek = [
  {
    id: 0,
    name: "Sunday"
  },
  {
    id: 1,
    name: 'Monday',
  },
  {
    id: 2,
    name: 'Tuesday',
  },
  {
    id: 3,
    name: 'Wednesday',
  },
  {
    id: 4,
    name: 'Thursday',
  },
  {
    id: 5,
    name: 'Friday',
  },
  {
    id: 6,
    name: 'Saturday',
  },
];


export const getDiscountDetails = (discount, type) => {
  const { discount_type, discounted_percent, discounted_amount, is_ffa, duration } = discount;
  switch (discount_type) {
    case 2:
      return `${discounted_percent}%`;
    case 1:
      return `$${(discounted_amount / 100).toFixed(2)} Flat`;
    case 3:
      return is_ffa ? 'One Day OFF' : `${duration} ${type === 1 ? 'Hours' : 'Days'} Free`;
    default:
      return `${duration} ${type === 1 ? 'Hours' : 'Days'} Free`;
  }
}

export const getRejectReason = (reason) => {
  switch (reason) {
    case 1:
      return "Not Suitable";
    case 2:
      return "Decided Against It";
    case 3:
      return "Not Ready - Future Potential";
    case 4:
      return "Unresponsive";
  }
}

export const showStatusBadge = (status) => {
  return (
    status === 4 ||
    status === 5 ||
    status === 0 ||
    status === 2 ||
    status === 3 ||
    status === 1 || status === -1
  );
};

export const getStatusBadgeText = (status) => {
  switch (status) {
    case 0:
      return {
        text: "Draft Listing",
        colorClass: "dark"
      };
    case 1:
      return {
        text: "Active",
        colorClass: "yellow"
      };
    case 2:
      return {
        text: "On Hold By User",
        colorClass: "info"
      };
    case 3:
      return {
        text: "On Hold By Admin",
        colorClass: "info"
      };
    case 4:
      return {
        text: "Pending For Approval",
        colorClass: "success"
      };
    case 5:
      return {
        text: "Approval Rejected",
        colorClass: "danger"
      };
    case -1:
      return {
        text: "Deleted",
        colorClass: "red"
      };

    default:
      return {
        text: "",
        colorClass: ""
      };
  }
};


export const stateList = [
  {
      "name": "Alabama",
      "abbreviation": "AL"
  },
  {
      "name": "Alaska",
      "abbreviation": "AK"
  },
  {
      "name": "American Samoa",
      "abbreviation": "AS"
  },
  {
      "name": "Arizona",
      "abbreviation": "AZ"
  },
  {
      "name": "Arkansas",
      "abbreviation": "AR"
  },
  {
      "name": "California",
      "abbreviation": "CA"
  },
  {
      "name": "Colorado",
      "abbreviation": "CO"
  },
  {
      "name": "Connecticut",
      "abbreviation": "CT"
  },
  {
      "name": "Delaware",
      "abbreviation": "DE"
  },
  {
      "name": "District Of Columbia",
      "abbreviation": "DC"
  },
  {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
  },
  {
      "name": "Florida",
      "abbreviation": "FL"
  },
  {
      "name": "Georgia",
      "abbreviation": "GA"
  },
  {
      "name": "Guam",
      "abbreviation": "GU"
  },
  {
      "name": "Hawaii",
      "abbreviation": "HI"
  },
  {
      "name": "Idaho",
      "abbreviation": "ID"
  },
  {
      "name": "Illinois",
      "abbreviation": "IL"
  },
  {
      "name": "Indiana",
      "abbreviation": "IN"
  },
  {
      "name": "Iowa",
      "abbreviation": "IA"
  },
  {
      "name": "Kansas",
      "abbreviation": "KS"
  },
  {
      "name": "Kentucky",
      "abbreviation": "KY"
  },
  {
      "name": "Louisiana",
      "abbreviation": "LA"
  },
  {
      "name": "Maine",
      "abbreviation": "ME"
  },
  {
      "name": "Marshall Islands",
      "abbreviation": "MH"
  },
  {
      "name": "Maryland",
      "abbreviation": "MD"
  },
  {
      "name": "Massachusetts",
      "abbreviation": "MA"
  },
  {
      "name": "Michigan",
      "abbreviation": "MI"
  },
  {
      "name": "Minnesota",
      "abbreviation": "MN"
  },
  {
      "name": "Mississippi",
      "abbreviation": "MS"
  },
  {
      "name": "Missouri",
      "abbreviation": "MO"
  },
  {
      "name": "Montana",
      "abbreviation": "MT"
  },
  {
      "name": "Nebraska",
      "abbreviation": "NE"
  },
  {
      "name": "Nevada",
      "abbreviation": "NV"
  },
  {
      "name": "New Hampshire",
      "abbreviation": "NH"
  },
  {
      "name": "New Jersey",
      "abbreviation": "NJ"
  },
  {
      "name": "New Mexico",
      "abbreviation": "NM"
  },
  {
      "name": "New York",
      "abbreviation": "NY"
  },
  {
      "name": "North Carolina",
      "abbreviation": "NC"
  },
  {
      "name": "North Dakota",
      "abbreviation": "ND"
  },
  {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
  },
  {
      "name": "Ohio",
      "abbreviation": "OH"
  },
  {
      "name": "Oklahoma",
      "abbreviation": "OK"
  },
  {
      "name": "Oregon",
      "abbreviation": "OR"
  },
  {
      "name": "Palau",
      "abbreviation": "PW"
  },
  {
      "name": "Pennsylvania",
      "abbreviation": "PA"
  },
  {
      "name": "Puerto Rico",
      "abbreviation": "PR"
  },
  {
      "name": "Rhode Island",
      "abbreviation": "RI"
  },
  {
      "name": "South Carolina",
      "abbreviation": "SC"
  },
  {
      "name": "South Dakota",
      "abbreviation": "SD"
  },
  {
      "name": "Tennessee",
      "abbreviation": "TN"
  },
  {
      "name": "Texas",
      "abbreviation": "TX"
  },
  {
      "name": "Utah",
      "abbreviation": "UT"
  },
  {
      "name": "Vermont",
      "abbreviation": "VT"
  },
  {
      "name": "Virgin Islands",
      "abbreviation": "VI"
  },
  {
      "name": "Virginia",
      "abbreviation": "VA"
  },
  {
      "name": "Washington",
      "abbreviation": "WA"
  },
  {
      "name": "West Virginia",
      "abbreviation": "WV"
  },
  {
      "name": "Wisconsin",
      "abbreviation": "WI"
  },
  {
      "name": "Wyoming",
      "abbreviation": "WY"
  }
]